import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'xsmall']),
  variant: PropTypes.oneOf(['default', 'toggle']),
  disabled: PropTypes.bool,
};
const defaultProps = {
  isLoading: false,
  className: '',
  size: 'small',
  id: null,
  disabled: false,
  variant: 'default',
};

function SwitchButton({ id, isActive, isLoading, className, onChange, size, disabled, variant }) {
  return (
    <button
      className={classNames(
        `btn btn-switch btn-switch--modern ${size} ${className} btn-switch--${variant}`,
        {
          off: !isActive,
          processing: isLoading,
        },
      )}
      id={id}
      type="button"
      onClick={onChange}
      disabled={disabled}
    >
      <span className="sr-only">Toggle</span>
    </button>
  );
}

SwitchButton.propTypes = propTypes;
SwitchButton.defaultProps = defaultProps;

export default SwitchButton;
